<ng-container>
  <ngx-ui-loader loaderId="log-information"></ngx-ui-loader>
  <ng-container>
    <mat-toolbar class="dialog-top-bar" fxLayout="row" fxLayoutAlign="space-between center">
      <div></div>
      <div *ngIf="showNotRunning==true" class="title">Campaign Task Status
      </div>
      <div *ngIf="showNotRunning==false" class="title">Total Systems
      </div>
      <div fxLayout="row">
       
      </div>
    </mat-toolbar>
    <div *ngIf="(run_report_systems.length==0) &&(listService.isLoading==false)" style="height: calc(100% - 140px)">
      <div *ngIf="showNotRunning==true&&show_parent_filter" class="selection-section" fxLayout="column" fxLayoutAlign="center start">
        <mat-form-field class="new-system-accordion bg-purple" style="    width: 100%;
        
        margin-top: 0;">
          <mat-label class="level-filter">{{filterKey}}</mat-label>
          <!-- (selectionChange)="getChildFilterMenuList($event.value)" -->
          <mat-select [(ngModel)]="filterKey" (selectionChange)="getActions($event.value)">
            <mat-option *ngFor="let filter of parent_menu_list" [value]="filter">{{filter
              | capitalizeText}}</mat-option>
          </mat-select>
        </mat-form-field>
    
      </div>
      <div class="selection-section" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

        <div fxLayout="column" fxLayoutAlign="center center" class="width-full">
          <img src="../../../../../assets/images/empty-box.png" style="height: 320px;width: 250px;" alt="no-systems" />
          <p class="custom-time">No Systems Found</p>


        </div>

      </div>

    </div>


   

    <div *ngIf="!(run_report_systems.length==0)" class="sidebar-list-view sidenav_segmentcount"
      style="height: calc(100% - 140px)" [ngClass]="listService.isLoading ? 'blur-effect' : ''" #top infiniteScroll
      [fromRoot]="listService.fromRoot" (scrolled)="listService.getList(this.list_actions?actions_lists[i_index]:  lists_run_report[0], false)"
      [infiniteScrollDistance]="listService.scrollDistance" [infiniteScrollThrottle]="listService.scrollThrottle"
      [infiniteScrollContainer]="listService.scrollContainer"
      [infiniteScrollDisabled]="scrollDisabled || this.list_actions? actions_lists[i_index]?.scrollDisabled : lists_run_report[0]?.scrollDisabled">
      <div *ngIf="showNotRunning==true&&show_parent_filter" class="selection-section" fxLayout="column" fxLayoutAlign="center start">
        <mat-form-field class="new-system-accordion bg-purple" style="    width: 100%;
        
        margin-top: 0;">
          <mat-label class="level-filter">{{filterKey}}</mat-label>
          <!-- (selectionChange)="getChildFilterMenuList($event.value)" -->
          <mat-select [(ngModel)]="filterKey" (selectionChange)="getActions($event.value)">
            <mat-option *ngFor="let filter of parent_menu_list" [value]="filter">{{filter
              |capitalizeText}}</mat-option>
          </mat-select>
        </mat-form-field>
    
      </div>

      <div class="selection-section" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px"
        style="width: 88%;">
        <div class="width-full profile-padding option-section" *ngFor="let system of run_report_systems; let i = index">
         


          <div fxLayout="column" fxLayoutAlign="center start" class="width-full" *ngIf="isRunningAction==true">
            <div style="flex-direction: row;
            box-sizing: border-box;
            display: flex;
            place-content: space-between;
            align-items: stretch;" class="width-full">
              <mat-label style="max-width: 100%;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;" class="custom-date">SN: <a class="color-orange-tag cursor-point" target="_blank" [routerLink]="['/admin/system/update-system/', system?.system?.id]" *ngIf="system?.system?.system_serial_number">

                {{ system?.system?.system_serial_number? system?.system?.system_serial_number: 'N/A'
                
                }}
              </a> <a *ngIf="!system?.system?.system_serial_number">N/A</a> </mat-label>
                <!-- <span class="status-badge {{system.status.toLowerCase()}}">
                  {{ system.status | capitalizeText }}
                </span> -->

            </div>
            <div *ngIf="system?.system?.customer?.id" fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding">
              <a class="custom-time color-orange-tag cursor-point"
              target="_blank"   [routerLink]="system?.system?.customer?.id ? ['/admin/customers/update-customer/', system?.system?.customer?.id] : null">
                {{ system?.system?.customer?.first_name ? (system?.system?.customer?.first_name + ' ' +
                system?.system?.customer?.last_name) : '-' }}  <span *ngIf="system?.system?.customer?.city||system?.system?.customer?.state">
                  ({{ system?.system?.customer?.city ? (system?.system?.customer?.city + ' ') : '' }}
                  {{ system?.system?.customer?.state ? system?.system?.customer?.state : '' }})
                </span></a>
            
            </div>
            <div *ngIf="!system?.system?.customer?.id" fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding">
              <a class="custom-time"
              >
                Customer:   <span>N/A
                </span></a>
            
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding mt-1">
              <a class="color-orange-tag cursor-point" target="_blank" [routerLink]="['/admin/system/update-system/', system?.system?.id]">{{
                system?.system?.mac_address
                }}  <span style="font-size: 12px;" class="custom-time status {{system?.system?.status}}">({{ system?.system?.status |titleCase}})</span></a>
                <span class="system-connectivity-capsule status  {{system?.system?.system_status}}">
                  {{ (system?.system?.system_status | titleCase)}}
                </span>

            </div>
          
        
           

            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding">
              <p class="custom-time"
              >
              Campaign: <span class="status {{system?.action_camp?.status.toLowerCase()}}">{{system?.action_camp?.status | titleCase}}</span>
            </p>
              <p class="system-connectivity-capsule status {{system?.status?.toLowerCase()}}"><span>
                {{ system.status | capitalizeText }}
              </span>
              </p>
            </div>

          </div>


































          <div fxLayout="column" fxLayoutAlign="center start" class="width-full" *ngIf="isRunningAction==false">
            
            <div style="flex-direction: row;
            box-sizing: border-box;
            display: flex;
            place-content: space-between;
            align-items: stretch;" class="width-full" >
              <mat-label style="max-width: 100%;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;" class="custom-date">SN:  <a *ngIf="system?.system_serial_number!=null" target="_blank" class="custom-time color-orange-tag cursor-point"   [routerLink]="['/admin/system/update-system/', system?.id]">{{ system?.system_serial_number!=null? system?.system_serial_number: 'N/A'
                }}</a>
              <a *ngIf="system?.system_serial_number==null"  class="custom-time" >{{ 'N/A'
                }}</a>  
              </mat-label>
              

            </div>
            <div *ngIf="system?.customer?.id" fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding">
              <a class="custom-time color-orange-tag cursor-point"
              target="_blank"   [routerLink]="system?.customer?.id ? ['/admin/customers/update-customer/', system.customer.id] : null">
                {{ system.customer?.first_name ? (system.customer.first_name + ' ' +
                system.customer.last_name) : '-' }}  <span *ngIf="system.customer.city||system.customer.state">
                  ({{ system.customer?.city ? (system.customer.city +','+ ' ') : '' }}
                  {{ system.customer?.state ? system.customer.state : '' }})
                </span></a>
            
            </div>
            <div *ngIf="!system?.customer?.id" fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding">
              <a class="custom-time"
              >
                Customer:   <span>N/A
                </span></a>
            
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding mt-1">
              <a class="color-orange-tag cursor-point" target="_blank" [routerLink]="['/admin/system/update-system/', system?.id]">{{
                system.mac_address
                }}  <span style="font-size: 12px;" class="custom-time status {{system?.status}}">({{ system?.status |titleCase}})</span></a>
                <span class="system-connectivity-capsule status  {{system.system_status}}">
                  {{ system?.system_status | titleCase}}
                </span>

                <!-- <span class="status-badge not-running" *ngIf="showNotRunning">
                  Not Running 
                </span> -->
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding" *ngIf="showNotRunning">
              <p class="custom-time"
              >
              Campaign: <span class="status {{this.campObj.status.toLowerCase()}}">{{this.campObj.status|titleCase}}</span>
            </p>
              <!-- <p class="system-connectivity-capsule not-running">Not Created
              </p> -->
            </div>
            
            
           
            



          </div>
        </div>
      </div>

    </div>



    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px" class="bottom-fixed action-button-group">
      <button mat-stroked-button color="warn" (click)="sidebarToggle()">
        Cancel
      </button>
      <!-- <button mat-raised-button color="warn" (click)="logType = 'all'; getSystemLogsList(id)">Back</button> -->
    </div>
  </ng-container>
</ng-container>