<ng-container>
  <ngx-ui-loader loaderId="log-information"></ngx-ui-loader>
  <ng-container>
    <mat-toolbar class="dialog-top-bar" fxLayout="row" fxLayoutAlign="space-between center">
      <div></div>
      <div class="title">Total Systems</div>
      <div fxLayout="row">
       
      </div>
    </mat-toolbar>
    <div *ngIf="(run_report_systems.length==0) &&(listService.isLoading==false)" style="height: calc(100% - 140px)">
      <div class="selection-section" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

        <div fxLayout="column" fxLayoutAlign="center center" class="width-full">
          <img src="../../../../../assets/images/empty-box.png" style="height: 320px;width: 250px;" alt="no-systems" />
          <p class="custom-time">No Systems Found</p>


        </div>

      </div>

    </div>




    <div *ngIf="!(run_report_systems.length==0)" class="sidebar-list-view sidenav_segmentcount"
      style="height: calc(100% - 140px)" [ngClass]="listService.isLoading ? 'blur-effect' : ''" #top infiniteScroll
      [fromRoot]="listService.fromRoot" (scrolled)="listService.getList(lists_run_report[0], false)"
      [infiniteScrollDistance]="listService.scrollDistance" [infiniteScrollThrottle]="listService.scrollThrottle"
      [infiniteScrollContainer]="listService.scrollContainer"
      [infiniteScrollDisabled]="scrollDisabled || lists_run_report[0].scrollDisabled">
      <div class="selection-section" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px"
        style="width: 88%;">
        <div class="width-full profile-padding option-section" *ngFor="let system of run_report_systems; let i = index">

          <div fxLayout="column" fxLayoutAlign="center start" class="width-full" *ngIf="isRunningAction==true">
            <div style="flex-direction: row;
            box-sizing: border-box;
            display: flex;
            place-content: space-between;
            align-items: stretch;" class="width-full">
              <mat-label style="max-width: 64%;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;" class="custom-date">Serial Number {{ system?.system?.system_serial_number
                }}</mat-label>
                <span class="status-badge {{system.status.toLowerCase()}}">
                  {{ system.status | capitalizeText }}
                </span>

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding mt-1">
              <mat-label class="color-orange-tag cursor-point  " [routerLink]="['/admin/system/update-system/', system?.system?.id]">{{
                 system?.system?.mac_address
                }}</mat-label>
              <p class="custom-time status {{system?.system?.status}}" style="margin-right: 36px;">{{ system?.system?.status |titleCase}}</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding">
              <p class="custom-time color-orange-tag cursor-point"
                [routerLink]="system?.system?.customer?.id ? ['/admin/customers/update-customer/', system?.system?.customer.id] : null">
                {{ system?.system?.customer?.first_name ? (system?.system?.customer.first_name + ' ' +
                system?.system?.customer.last_name) : '-' }}</p>
              <p class="custom-time">{{ system?.system?.customer?.city ? (system?.system?.customer.city + ' ') : '' }}
                {{ system?.system?.customer?.country ? system?.system?.customer.country : '' }}
              </p>
            </div>


          </div>


































          <div fxLayout="column" fxLayoutAlign="center start" class="width-full" *ngIf="isRunningAction==false">
            
            <div style="flex-direction: row;
            box-sizing: border-box;
            display: flex;
            place-content: space-between;
            align-items: stretch;" class="width-full">
              <mat-label style="max-width: 64%;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;" class="custom-date">Serial Number {{ system.system_serial_number
                }}</mat-label>
                <span class="status-badge not-running">
                  Not Running 
                </span>

            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding mt-1">
              <mat-label class="color-orange-tag cursor-point" [routerLink]="['/admin/system/update-system/', system?.id]">{{
                system.mac_address
                }}</mat-label>
              <p class="custom-time status {{system?.status}}" style="margin-right: 36px;">{{ system?.status |titleCase}}</p>
            </div>
            
            
            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-full inner-profile-padding">
              <p class="custom-time color-orange-tag cursor-point"
                [routerLink]="system?.customer?.id ? ['/admin/customers/update-customer/', system.customer.id] : null">
                {{ system.customer?.first_name ? (system.customer.first_name + ' ' +
                system.customer.last_name) : '-' }}</p>
              <p class="custom-time">{{ system.customer?.city ? (system.customer.city + ' ') : '' }}
                {{ system.customer?.country ? system.customer.country : '' }}
              </p>
            </div>


          </div>
        </div>
      </div>

    </div>



    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px" class="bottom-fixed action-button-group">
      <button mat-stroked-button color="warn" (click)="sidebarToggle()">
        Cancel
      </button>
      <!-- <button mat-raised-button color="warn" (click)="logType = 'all'; getSystemLogsList(id)">Back</button> -->
    </div>
  </ng-container>
</ng-container>