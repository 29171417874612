import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {

    snackBar: MatSnackBar;

    constructor(private _snackBar: MatSnackBar) {
        this.snackBar = _snackBar;
    }

    public toast(message: string, action: string = '', duration: number = 3000) {
        this.snackBar.open(message, action, { duration: duration });
    }

}
