import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService } from './services/dialog.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../modules/material.module';
import { NavigationService } from './services/navigation.service';
import { ErrorComponent } from './components/error/error.component';
import { LoadingService } from './services/loader.service';
import { NotificationService } from './services/notification.service';
import { SharedDataService } from './services/shared-data.service';
import { AppLoadService } from './services/app-load.service';
import { HeaderComponent } from './components/header/header.component';
import { AuthService } from './services/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { DataService } from './services/data.service';
import { FullNamePipe } from './pipes/full-name.pipe';
import { CanLoadModuleGuard } from './guard/can-load-module.guard';
import { AuthGuard } from './guard/auth.guard';
import { ListService } from './services/list.service';
import { DateAgePipe } from './pipes/date-age.pipe';
import { ProjectTypePipe } from './pipes/project-type.pipe';
import { TrackByPropertyPipe } from './pipes/track-by-property.pipe';
import { ShortNamePipe } from './pipes/short-name.pipe';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { AlarmTypePipe } from './pipes/alarm-type.pipe';
import { EmptyScreenComponent } from './components/empty-screen/empty-screen.component';
import { ModuleAccessGuard } from './guard/module-access.guard';
import { ActivityDetailComponent } from './sidebar/activity-detail/activity-detail.component';
import { RouterModule } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { ActionTypeHeaderComponent } from './components/action-type-header/action-type-header.component';
import { NewActionTypeHeaderComponent } from './components/new-action-type-header/new-action-type-header.component';
import { SubHeaderStatsComponent } from './components/sub-header-stats/sub-header-stats.component';
import { MultiButtonHeaderComponent } from './components/multi-button-header/multi-button-header.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { FileNamePipe } from './pipes/file-name.pipe';
import { TitleCasePipe } from './pipes/title-case.pipe';
import { ActionStatusPipe } from './pipes/action-status.pipe';
import { ClearingStatusPipe } from './pipes/clearing-status.pipe';
import { MinutesToDaysAbbreviationPipe } from './pipes/minutes-to-days-abbreviation.pipe';
import { WaterQuantityLabelPipe, WaterQuantityPipe } from './pipes/water-quantity.pipe';
import { CustomDatePipe } from './pipes/custome-datetime.pipe';
import { CapitalizeTextPipe } from './pipes/capitalize-text.pipe';
import { UtcToLocalPipe } from './pipes/utc-to-local.pipe';
import { SystemDetailsComponent } from './sidebar/system-details/system-details.component';
import { CampaignListComponent } from './sidebar/campain-lists/campaign-lists.component';
import { TitleCasePipe2 } from './pipes/title-case2.pipe';
// import { SidenavService } from './services/sidenav.service';

// Restore shared data from localstorage before app loads
export function restoreSharedData(appLoadService: AppLoadService) {
  return () => appLoadService.restoreSharedData();
}
@NgModule({
  declarations: [
    HeaderComponent,
    ErrorComponent,
    FullNamePipe,
    ShortNamePipe,
    DateAgePipe,
    MinutesToDaysAbbreviationPipe,
    DateFormatPipe,
    FileNamePipe,
    TitleCasePipe,
    TitleCasePipe2,
    CustomDatePipe,
    WaterQuantityPipe, WaterQuantityLabelPipe,
    ProjectTypePipe,
    TrackByPropertyPipe,
    ActionStatusPipe,
    ClearingStatusPipe,
    ConfirmComponent,
    ImagePreviewComponent,
    AlarmTypePipe,
    EmptyScreenComponent, UtcToLocalPipe,
    ActivityDetailComponent, SystemDetailsComponent,CampaignListComponent,
    SubHeaderComponent, CapitalizeTextPipe,
    SubHeaderStatsComponent,
    MultiButtonHeaderComponent,
    ActionTypeHeaderComponent,
    NewActionTypeHeaderComponent
  ],
  imports: [
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    RouterModule,
    NgxUiLoaderModule,
    InfiniteScrollModule
  ],
  exports: [
    HeaderComponent,
    SubHeaderComponent,
    SubHeaderStatsComponent,
    MultiButtonHeaderComponent,
    ActionTypeHeaderComponent,
    NewActionTypeHeaderComponent,
    FlexLayoutModule,
    MaterialModule,
    FullNamePipe,
    ShortNamePipe,
    CustomDatePipe,
    DateAgePipe,
    MinutesToDaysAbbreviationPipe,
    DateFormatPipe,
    FileNamePipe,TitleCasePipe2,
    TitleCasePipe,
    WaterQuantityPipe,
    WaterQuantityLabelPipe,
    ProjectTypePipe, CapitalizeTextPipe,
    TrackByPropertyPipe,
    ActionStatusPipe,
    ClearingStatusPipe,
    ConfirmComponent,
    ImagePreviewComponent,
    AlarmTypePipe, UtcToLocalPipe,
    EmptyScreenComponent,
    ActivityDetailComponent, SystemDetailsComponent,CampaignListComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DialogService,
    NavigationService,
    LoadingService,
    NotificationService,
    SharedDataService,
    AppLoadService,
    AuthService,
    DataService,
    ListService,
    WaterQuantityLabelPipe, UtcToLocalPipe,
    WaterQuantityPipe,
    // SidenavService,
    CanLoadModuleGuard,
    ModuleAccessGuard,
    AuthGuard,
    { provide: APP_INITIALIZER, useFactory: restoreSharedData, deps: [AppLoadService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class CoreModule { }
