import { Injectable } from '@angular/core';
import { DataService } from './data.service';


export interface Config {
  self: any; // Reference to the component using this configuration
  listName: string; // Name of the list to be managed
  url: string; // Base URL for fetching list data
  searchUrl?: string; // URL for search operations (optional)
  callback?: any; // Callback function to execute after data fetch (optional)
  skip?: number; // Number of records to skip for pagination (optional)
  limit?: number; // Number of records to fetch per page (optional)
  scrollContainer?: any; // Selector for scroll container (optional)
  fields?: string[]; // Fields to include in search query (optional)
  check?: any[]; // Additional check parameters (optional)
  scrollDisabled?: boolean; // Flag to disable scrolling (optional)
  
}

@Injectable({
  providedIn: 'root'
})
export class ListService {

   // Public properties for managing list and scroll behavior
   public isLoading = false; // Flag indicating if data is being loaded
   public statsData: any; // Stores statistics data
   public fromRoot = true; // Flag indicating if service is from root
   public scrollDistance = 1; // Distance for scroll event
   public scrollThrottle = 150; // Throttle time for scroll events
   public scrollDisabled = false; // Flag to disable scroll (used for future reference)
   public scrollContainer = '.mat-sidenav-content'; // Default scroll container selector
   limit = 15; // Default limit for pagination

  constructor(
    private dataService: DataService // Service for data fetching
  ) {

  }
 /**
   * Initializes the list configuration and appends initial data.
   * @param config Configuration for the list
   * @param data Initial data to populate the list
   */
  init(config: Config, data = []) {
    config.skip = config.skip || 0;
    config.limit = config.limit || this.limit;
    console.log(config)
    config.self[config.listName] = [];

    this.scrollContainer = config.scrollContainer || '.mat-sidenav-content';
    this.appendResult(config, data);
  }
/**
   * Appends results to the list and manages pagination state.
   * @param config Configuration for the list
   * @param list List of items to append
   * @param retainLimit Flag to retain limit (optional)
   */
  appendResult(config: Config, list: any[], retainLimit?: boolean) {
    if (Array.isArray(list)) {
      config.self[config.listName] = config.skip == 0 ? list : config.self[config.listName].concat(list);

      // How many record need to skip for next time.
      // Enable or disable pagination based on condition.
      if (config.skip !== undefined && config.limit !== undefined) {
        config.skip += list.length;
        config.scrollDisabled = list.length < config.limit;
      }
    } else {
      config.scrollDisabled = true;
    }
  }
 /**
   * Fetches the list data based on the configuration and manages loading state.
   * @param config Configuration for the list
   * @param retainLimit Flag to retain limit (optional)
   * @param from Source data (optional)
   * @param loaderName Loader name for displaying loader (optional)
   */
  getList(config: any = {}, retainLimit = true, from?: any, loaderName?: any) {
  
    if (config?.self?.isSearch) {
      this.getSearchList(config, loaderName);
      return;
    }

    let limit = config.limit;
    if (retainLimit) {
      config.skip = 0;
      const len = config.self[config.listName]?.length;
      limit = len < config.limit ? config.limit : len;
    }

    this.isLoading = true;
    this.scrollDisabled = true;



    if (loaderName) {

      this.dataService.get({
        url: config.url + "skip=" + config.skip + "&limit=" + limit,
        loaderName: loaderName
      }).subscribe((response: any) => {
        this.isLoading = false;
        if (response && response.result) {
          console.log(response.result)
          if (typeof config.callback === "function") {
            config.callback(response.result);
          }
          this.appendResult(config, response.result.records, retainLimit);
        }
        if (response.result.stats) {
          this.statsData = response.result.stats;
        }
      }, () => {
        this.isLoading = false;
      });
    } else {

      this.dataService.get({
        url: config.url + "skip=" + config.skip + "&limit=" + limit,
        isLoader: false
      }).subscribe((response: any) => {
        this.isLoading = false;
        console.log(response.result)

        if (response && response.result) {
          if (typeof config.callback === "function") {
            config.callback(response.result);
          }
          this.appendResult(config, response.result.records, retainLimit);
        }
        if (response.result.stats) {
          this.statsData = response.result.stats;
        }
      }, () => {
        this.isLoading = false;
      });
    }





  }
 /**
   * Updates the URL for the API request based on the filter and fetches the filtered list.
   * @param config Configuration for the list
   * @param baseURL Base URL for the API request
   * @param filterString Filter query string
   * @param loaderName Loader name for displaying loader (optional)
   */
  setFilterApiUrl(config: any, baseURL: string, filterString: string, loaderName?: any) {
    config.url = baseURL + filterString;
    this.getList(config, true, '', loaderName);
  }
/**
   * Appends search results to the list and manages pagination state.
   * @param config Configuration for the list
   * @param list List of search results to append
   */
  appendSearchResult(config: any, list: any[]) {
    if (Array.isArray(list)) {
      config.self[config.listName] = config.skip == 0 ? list : config.self[config.listName].concat(list);
      // How many record need to skip for next time.
      // Enable or disable pagination based on condition.
      config.skip += list.length;

      config.scrollDisabled = list.length < config.limit;

      if (typeof config.callback === "function") {
        config.callback();
      }
    } else {
      config.scrollDisabled = true;
    }
  }
 /**
   * Fetches the search results based on the configuration.
   * @param config Configuration for the search
   * @param loaderName Loader name for displaying loader (optional)
   */
  getSearchList(config: any = {}, loaderName?: any) {
    if (!config.self.isSearch) {
      this.getList(config, true, '', loaderName);
      return;
    }

    const data = {
      // keyword: config.self.searchText.replace(/[`~!#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, ''),

      query: {
        keyword: config.self.searchText,
        fields: config.fields,
        offset: config.skip,
        limit: config.limit,
        check: config.check || []
      }
    };

    this.isLoading = true;
    if (loaderName) {
      this.dataService.post({
        url: config.searchUrl,
        loaderName: loaderName,
        data
      }).subscribe((response: any) => {
        this.isLoading = false;
        if (response && response.result) {
          this.appendSearchResult(config, Array.isArray(response.result.records) ? response.result.records : response.result);
        }
      }, () => {
        this.isLoading = false;
      });
    } else {
      this.dataService.post({
        url: config.searchUrl,
        isLoader: false,
        data
      }).subscribe((response: any) => {
        this.isLoading = false;
        if (response && response.result) {
          this.appendSearchResult(config, Array.isArray(response.result.records) ? response.result.records : response.result);
        }
      }, () => {
        this.isLoading = false;
      });
    }
  }
/**
   * Initiates a search based on the configuration.
   * @param config Configuration for the search
   * @param loaderName Loader name for displaying loader (optional)
   */
  search(config: any = {}, loaderName?: any) {
    
    if (config.self.searchText) {
      config.self.isSearch = true;
      config.skip = config.skip = 0;
      config.limit = config.limit = this.limit;
      this.getSearchList(config, loaderName);
    } else if (config.self.searchText === '') {
      this.clearSearch(config, loaderName);
    }
  }
/**
   * Clears the search text and fetches the list without search filter.
   * @param config Configuration for the list
   * @param loaderName Loader name for displaying loader (optional)
   */
  clearSearch(config: any = {}, loaderName?: any) {
    config.self.searchText = '';
    config.self.isSearch = false;
    config.skip = config.skip = 0;
    config.limit = config.limit = this.limit;
    this.getList(config, true, '', loaderName);
  }
}
