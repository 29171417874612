<ng-container>
  <ngx-ui-loader loaderId="log-information"></ngx-ui-loader>
  <ng-container *ngIf="logType == 'single'">
    <mat-toolbar class="dialog-top-bar" fxLayout="row" fxLayoutAlign="space-between center">
      <div></div>
      <div class="title">Log Details</div>
      <div></div>
    </mat-toolbar>
    <div class="sidebar-list-view" style="height: calc(100% - 140px)"
      [ngClass]="listService.isLoading ? 'blur-effect' : ''" #top infiniteScroll [fromRoot]="listService.fromRoot"
      (scrolled)="listService.getList(lists[0], false)" [infiniteScrollDistance]="listService.scrollDistance"
      [infiniteScrollThrottle]="listService.scrollThrottle" [infiniteScrollContainer]="listService.scrollContainer"
      [infiniteScrollDisabled]="scrollDisabled || lists[0].scrollDisabled">
      <div class="title-header">
        <greyter-header [class]="['', 'white']" [isButtonShow]="false">
          <ng-container id="headerLabel">Log Information</ng-container>
          <!-- <ng-container id="buttonLabel">Add New</ng-container> -->
        </greyter-header>
      </div>
      <div class="title-header" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="30" class="assign-name">Log</div>
        <div fxFlex="70" class="description">
          {{ logDetail?.log }}
        </div>
      </div>
      <div class="title-header" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="30" class="assign-name">Log Date</div>
        <div fxFlex="70" class="description">
          {{ logDetail?.timestamp * 1000 | date : "mediumDate" : "UTC+0" }}
        </div>
      </div>
      <div class="title-header" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="30" class="assign-name">Log Time</div>
        <div fxFlex="70" class="description">
          {{ logDetail?.timestamp * 1000 | date : "shortTime" : "UTC+0" }}
        </div>
      </div>

      <div class="title-header" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="30" class="assign-name">Serial No.</div>
        <div fxFlex="70" class="cursor-point color-orange-tag"
          [routerLink]="'/admin/system/update-system/' + logDetail?.id">
          {{ logDetail.system_serial_number }}
        </div>
      </div>

      <div class="title-header" fxLayout="row" fxLayoutAlign="start start">
        <div fxFlex="30" class="assign-name">Notes</div>
        <div fxFlex="70" class="description">
          {{ logDetail?.alarm?.description }}
        </div>
      </div>

      <div fxLayout="column" *ngIf="systemHistoryList?.length">
        <div class="title-header">
          <greyter-header [class]="['', 'white']" [isButtonShow]="false">
            <ng-container id="headerLabel">Recent History</ng-container>
            <!-- <ng-container id="buttonLabel">Add New</ng-container> -->
          </greyter-header>
        </div>
        <ng-container *ngFor="let alarmHistory of systemHistoryList">
          <div class="title-header">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="assign-name space-right">Log Name:</div>
              <div class="description">{{ alarmHistory?.log }}</div>
            </div>
            <div>
              {{
              alarmHistory?.timestamp * 1000 | date : "mediumDate" : "UTC+0"
              }}
              <br>{{
              alarmHistory?.timestamp * 1000 | date : "shortTime" : "UTC+0"
              }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px" class="bottom-fixed action-button-group">
      <button mat-stroked-button color="warn" (click)="sidebarToggle()">
        Cancel
      </button>
      <!-- <button mat-raised-button color="warn" (click)="logType = 'all'; getSystemLogsList(id)">Back</button> -->
    </div>
  </ng-container>
  <ng-container *ngIf="logType == 'all'">
    <mat-toolbar class="dialog-top-bar" fxLayout="row" fxLayoutAlign="space-between center">
      <div></div>
      <div class="title">Total Active Alerts</div>
      <div></div>
    </mat-toolbar>

    <div class="sidebar-list-view" style="height: calc(100% - 140px)"
      [ngClass]="listService.isLoading ? 'blur-effect' : ''" #top infiniteScroll [fromRoot]="listService.fromRoot"
      (scrolled)="listService.getList(lists[0], false)" [infiniteScrollDistance]="listService.scrollDistance"
      [infiniteScrollThrottle]="listService.scrollThrottle" [infiniteScrollContainer]="listService.scrollContainer"
      [infiniteScrollDisabled]="scrollDisabled || lists[0].scrollDisabled">
      <div class="profile-padding" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div class="user-img-holder">
          <img *ngIf="systemInfo?.customer?.avatar" class="img-replacer" crossorigin="anonymous"
            [src]="systemInfo?.customer?.avatar" alt="No Image" />

          <span *ngIf="!systemInfo?.customer?.avatar" class="img-alt color-orange-tag">{{
            systemInfo?.customer | shortName
            }}</span>
        </div>
        <div>
          <div *ngIf="userDetails.is_admin" class="assign-name cursor-point color-orange-tag" [routerLink]="[
              '/admin/customers/update-customer/' + systemInfo?.customer?.id
            ]">
            {{ systemInfo?.customer | fullName }}
          </div>

          <div *ngIf="!userDetails.is_admin" class="assign-name">
            {{ systemInfo?.customer | fullName }}
          </div>

          <div class="description" *ngIf="userDetails.is_admin">
            {{ systemInfo?.customer?.city }}
            {{ systemInfo?.customer?.state }}
            {{ systemInfo?.customer?.country }}
          </div>
        </div>
      </div>
      <div class="profile-padding">
        <div class="description" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
          <div>Serial No:</div>
          <div class="cursor-point color-orange-tag" [routerLink]="'/admin/system/update-system/' + systemInfo?.id">
            {{ systemInfo.system_serial_number }}
          </div>
        </div>
        <div class="description" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
          <div>Status:</div>
          <div class="status {{ systemInfo?.status }}">
            {{ systemInfo?.status | titleCase }}
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <div class="list-item top-padding-no-img" *ngFor="let log of systemLogs">
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="99">
              <div class="assign-name ellipsis-335">
                {{ log?.log || "N/A" }}
              </div>
              <div class="mt-1">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="description">
                  <div class="cursor-point color-orange-tag" [routerLink]="
                      '/admin/system/update-system/' + systemInfo?.id
                    ">
                    {{ systemInfo.system_serial_number }}
                  </div>
                  <div>
                    {{ log?.timestamp * 1000 | date : "mediumDate" : "UTC+0" }}
                    <!-- {{ log?.timestamp * 1000 | date : "mediumDate" : "UTC+0" }} -->
                  </div>
                  <div>
                    <!-- {{ log?.timestamp * 1000 | date : "shortTime" : "UTC+0" }} -->
                    {{ log?.timestamp * 1000 | date : "shortTime" : "UTC+0" }}
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex *ngIf="isShowLogDetail">
              <mat-icon (click)="
                  logType = 'single';
                  logDetail = log;
                  getSameLogHistoryLogName(log?.system, log?.log_key,log)
                " class="cursor-point" svgIcon="icon-ico-right-arrow">
              </mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px" class="bottom-fixed action-button-group">
      <button mat-stroked-button color="warn" (click)="sidebarToggle()">
        Cancel
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="logType == 'picksystem'">
    <mat-toolbar class="dialog-top-bar" fxLayout="row" fxLayoutAlign="space-between center">
      <div></div>
      <div class="title">Pick System</div>
      <div></div>
    </mat-toolbar>

    <div class="sidebar-list-view" style="height: calc(100% - 140px)"
      [ngClass]="listService.isLoading ? 'blur-effect' : ''" #top infiniteScroll [fromRoot]="listService.fromRoot"
      (scrolled)="listService.getList()" [infiniteScrollDistance]="listService.scrollDistance"
      [infiniteScrollThrottle]="listService.scrollThrottle" [infiniteScrollContainer]="listService.scrollContainer"
      [infiniteScrollDisabled]="scrollDisabled || listService.scrollDisabled">
      <div class="selection-section" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center start" class="width-full profile-padding option-section active">
          <img src="../../../../../assets/images/Tickldpi.svg" alt="brand-logo" class="tick-image" />
          <div fxLayout="column" fxLayoutAlign="center start" class="width-full">
            <mat-label class="custom-mat-label color-orange-tag">Mac Address 1</mat-label>
            <p>Customer Name 1</p>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-60">
              <p class="active-text">Active</p>
              <p>Project Type 1</p>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center start" class="width-full profile-padding option-section">
          <img src="../../../../../assets/images/Tickldpi.svg" alt="brand-logo" class="tick-image" />
          <div fxLayout="column" fxLayoutAlign="center start" class="width-full">
            <mat-label class="custom-mat-label color-orange-tag">Mac Address 2</mat-label>
            <p>Customer Name 1</p>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-60">
              <p class="">Disabled</p>
              <p>Project Type 2</p>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center start" class="width-full profile-padding option-section active">
          <img src="../../../../../assets/images/Tickldpi.svg" alt="brand-logo" class="tick-image" />
          <div fxLayout="column" fxLayoutAlign="center start" class="width-full">
            <mat-label class="custom-mat-label color-orange-tag">Mac Address 1</mat-label>
            <p>Customer Name 1</p>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-60">
              <p class="">Disabled</p>
              <p>Project Type 1</p>
            </div>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center start" class="width-full profile-padding option-section">
          <img src="../../../../../assets/images/Tickldpi.svg" alt="brand-logo" class="tick-image" />
          <div fxLayout="column" fxLayoutAlign="center start" class="width-full">
            <mat-label class="custom-mat-label color-orange-tag">Mac Address 2</mat-label>
            <p>Customer Name 1</p>
            <div fxLayout="row" fxLayoutAlign="space-between center" class="width-60">
              <p class="active-text">Active</p>
              <p>Project Type 2</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="action-button-group" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
      <button mat-stroked-button color="warn" type="button" (click)="sidebarToggle()">
        Cancel
      </button>
      <button mat-raised-button color="warn" type="submit">Insert</button>
    </div>
  </ng-container>
  <ng-container *ngIf="logType == 'pickproject'">
    <mat-toolbar class="dialog-top-bar" fxLayout="row" fxLayoutAlign="space-between center">
      <div></div>
      <div class="title">Pick Projects</div>
      <div></div>
    </mat-toolbar>

    <div class="sidebar-list-view" style="height: calc(100% - 140px)"
      [ngClass]="listService.isLoading ? 'blur-effect' : ''" #top infiniteScroll [fromRoot]="listService.fromRoot"
      (scrolled)="listService.getList()" [infiniteScrollDistance]="listService.scrollDistance"
      [infiniteScrollThrottle]="listService.scrollThrottle" [infiniteScrollContainer]="listService.scrollContainer"
      [infiniteScrollDisabled]="scrollDisabled || listService.scrollDisabled">
      <div class="selection-section" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center start" class="width-full profile-padding option-section active">
          <img src="../../../../../assets/images/Tickldpi.svg" alt="brand-logo" class="tick-image" />
          <div fxLayout="column" fxLayoutAlign="center start" class="width-full">
            <mat-label class="custom-mat-label">Project Name 1</mat-label>
            <p>Project Name 1</p>
            <p>Builder Name 1</p>
            <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="width-55">
                        <p class="active-text">Active</p>
                        <p>Builder Name 1</p>
                    </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="action-button-group" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
      <button mat-stroked-button color="warn" type="button" (click)="sidebarToggle()">
        Cancel
      </button>
      <button mat-raised-button color="warn" type="submit">Insert</button>
    </div>
  </ng-container>
  <ng-container *ngIf="logType == 'connectivity'">
    <mat-toolbar class="dialog-top-bar" fxLayout="row" fxLayoutAlign="space-between center">
      <div></div>
      <div class="title">{{ connectivityHeader }}</div>
      <div></div>
    </mat-toolbar>




    <div class="sidebar-list-view" style="height: calc(100% - 140px)" #top infiniteScroll
      [fromRoot]="listService.fromRoot" (scrolled)="listService.getList(lists[0], false)"
      [infiniteScrollDistance]="listService.scrollDistance" [infiniteScrollThrottle]="listService.scrollThrottle"
      [infiniteScrollContainer]="listService.scrollContainer"
      [infiniteScrollDisabled]="scrollDisabled || lists[0].scrollDisabled">
      <div class="selection-section" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="center start" class="width-full profile-padding option-section"
          *ngFor="let element of connectionActivities; let i = index">
          <div fxLayout="column" fxLayoutAlign="center start" class="width-full">
            <div fxLayout="row" fxLayoutAlign="space-between start" class="width-full">
              <mat-label class="custom-mat-label color-grey-tag">
                <span *ngIf="element.ping">{{
                  element.ping?.last_ping | utcToLocal:'mediumDate'
                  }}</span>
                <span *ngIf="!element.ping">-</span>
              </mat-label>
              <p>
                <span *ngIf="element.ping">{{
                  element.ping?.last_ping | utcToLocal:'longTime'
                  }}</span>
                <span *ngIf="!element.ping">-</span>
              </p>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start" class="width-full">
              <p>
                Mac:
                <span *ngIf="element.mac_address" class="color-orange-tag cursor-point"
                  [routerLink]="['/admin/system/update-system/', element.id]">
                  {{ element.mac_address }}
                </span>
                <span *ngIf="!element.mac_address">-</span>
              </p>
              <p class="status" [class]="element.system_status">
                {{ element.system_status |titleCase }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="selection-section" fxLayoutAlign="center start" fxLayoutGap="10px"
        *ngIf="connectionActivities.length==0&&(listService.isLoading==false)">
        <div fxLayout="row" fxLayoutAlign="center start">
          <div fxLayout="column" fxLayoutAlign="center center" class="width-full">
            <img src="../../../../../assets/images/empty-box.png" style="height: 320px;width: 250px;"
              alt="no-systems" />
            <p class="custom-time">No Data Found</p>


          </div>
        </div>
      </div>
    </div>

    <div class="action-button-group" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
      <button mat-stroked-button color="warn" type="button" (click)="sidebarToggle()" class="cancle-btn">
        Cancel
      </button>
    </div>
  </ng-container>
</ng-container>